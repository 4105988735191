import { AuthService } from '$services/auth'

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $auth: AuthService
    }
}

export default defineNuxtPlugin({
    name: 'auth-service',
    dependsOn: [
        'i18n:plugin'
    ],
    setup(_nuxtApp) {
        const runtimeConfig = useRuntimeConfig()
        const authServiceProvider = new AuthService({
            ...runtimeConfig.public.auth
        })
        return {
            provide: {
                auth: authServiceProvider
            }
        }
    }
})
