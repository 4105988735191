export default defineNuxtPlugin((_nuxtApp) => {
    if (import.meta.server) {
        // console.log('Nuxt App:', nuxtApp)
    }

    let params: any = {}

    addRouteMiddleware('global-params', (to, _from) => {
        params = to.params
    }, { global: true })

    return {
        provide: {
            getParams(): any {
                return params
            }
        }
    }
})
