import type { AuthService } from './auth'

export abstract class JBGApi {
    private baseUrl: string
    private auth: AuthService | undefined
    constructor(baseUrl: string, authService?: AuthService) {
        this.baseUrl = baseUrl.replace(/\/$/, '') // trim trailing slash
        this.auth = authService
    }
    // getUrl trims preceding slash
    private getUrl(route: string): string {
        return `${this.baseUrl}/${route.replace(/^\//, '')}`
    }

    // eslint-disable-next-line complexity
    public async callAPI<T>(route: string, options: RequestInit | undefined, refreshToken = true): Promise<T> {
        const url = this.getUrl(route)
        const response = await $fetch.native(url, options)
        let isJsonRes = false
        const contentType = response.headers.get('content-type')
        if (contentType && contentType.indexOf('application/json') !== -1) {
            isJsonRes = true
        }
        // bad request, should have a `code` status to let the FE know how to handle
        if (response.status === 400) {
            const err = await response.json() as JBGApi.Error
            err.httpStatus = response.status
            if (isJsonRes) throw new JBGApi.Error(err.error, err.code, err.httpStatus)
            throw new Error('bad request')
        }
        // unauthorized, try to refresh
        if (response.status === 401) {
            if (this.auth && refreshToken) {
                await this.auth?.refreshToken()
                return this.callAPI(route, options, false)
            }
            if (isJsonRes) {
                const err = await response.json() as JBGApi.Error
                err.httpStatus = response.status
                throw new JBGApi.Error('unauthorized', err.code, err.httpStatus)
            } else {
                throw new JBGApi.Error('unauthorized')
            }
        }
        // successful
        if (response.status >= 200 && response.status < 300) {
            if (!isJsonRes || response.status === 201 || response.status === 202 || response.status === 204) {
                return response as T
            }
            const json = await response.json()
            return json
        }
        // all other requests
        if (isJsonRes) {
            const err = await response.json() as JBGApi.Error
            err.httpStatus = response.status
            throw new JBGApi.Error(err.error, err.code, err.httpStatus)
        }
        console.error('api internal service error')
        throw new Error()
    }

    public buildOptions(method: string, payload: any = null): RequestInit {
        const headers: HeadersInit = new Headers()
        headers.set('Accept', 'application/json')
        if (method.toLowerCase() !== 'get') {
            headers.set('Content-Type', 'application/json')
        }

        const options:RequestInit = {
            method: method.toUpperCase(),
            headers,
            mode: 'cors',
            credentials: 'include'
        }

        if (payload) {
            options.body = JSON.stringify(payload)
        }

        return options
    }
}

export namespace JBGApi {
    export const UserJBGExists = 2005
    export const UserInappropriateUsername = 2011
    export class Error {
        public code: number | undefined
        public error: string
        public httpStatus: number | undefined
        constructor(error: string, code?: number, httpStatus?: number) {
            this.error = error
            this.code = code
            this.httpStatus = httpStatus
        }
    }
}
