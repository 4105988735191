/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable no-underscore-dangle */
import { reactive } from 'vue'

import Shopify from '@jackboxgames/shopify'
import { GtmSupport, useGtm } from '@gtm-support/vue-gtm'

import { Geolocate } from '$services/geolocate'
import currencyCodeRegionMapJson from '$components/shopping/currencyCodeCloudfrontRegionMap.json'

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $shopify: {
            fetchCart(): Promise<void>
            addItemToCart(
                item: Shopify.Shopify.LineItem,
                qty?: number,
                product?: Shopify.Shopify.Product,
                variant?: Shopify.Shopify.Variant
            ): Promise<void>
            updateItemQuantity(item: Shopify.Shopify.Line, quantity: number): Promise<void>
            removeItemFromCart(lineItemId: string): Promise<void>
            refreshCart(): Promise<void>
            getCheckoutUrl(): string | null
            fetchCollection(id: number): Promise<Shopify.Shopify.Collection>
            fetchProduct(slug: string): Promise<Shopify.Shopify.Product>
            formatDecimalPlaces(priceAmount: string, locale: string): string
        }
    }
}

// Cart Tray UI Reactive Elements
export const ShoppingCart = reactive({
    _open: false,
    _items: [] as JBGShopify.DisplayItem[],
    _subtotal: {} as JBGShopify.DisplayPrice,
    _qty: 0,
    _checkoutUrl: '',
    // eslint-disable-next-line max-len
    updateCart(newItems: JBGShopify.DisplayItem[], newSubtotal: JBGShopify.DisplayPrice, newQty: number, newCheckoutUrl: string): void {
        this._items = newItems
        this._subtotal = newSubtotal
        this._qty = newQty
        this._checkoutUrl = newCheckoutUrl
    },
    getCart(): JBGShopify.DisplayItem[] {
        return this._items
    },
    getSubtotal(): JBGShopify.DisplayPrice {
        return this._subtotal
    },
    getQty(): number {
        return this._qty
    },
    getCheckoutUrl(): string {
        return this._checkoutUrl
    },
    isOpen(): boolean {
        return this._open
    },
    open(): void {
        this._open = true
        setTimeout(() => {
            document.cartProxy = this
            document.addEventListener('click', this.onDocumentClick)
            document.addEventListener('keyup', this.onEscPress)
        }, 50)

        const gtm = useGtm()
        if (gtm && this._items.length) {
            const evt = {
                event: 'view_cart',
                ecommerce: {
                    value: parseFloat(this._subtotal.amount),
                    currency: this._items[0].currency,
                    items: this._items.map((item, idx) => ({
                        // eslint-disable-next-line camelcase
                        item_id: item.productId,
                        // eslint-disable-next-line camelcase
                        item_name: item.title,
                        index: idx,
                        price: item.priceValue,
                        quantity: item.quantity
                    }))
                }
            }
            gtm.trackEvent(evt)
        }
    },
    close(): void {
        this._open = false
        document.cartProxy = this
        document.removeEventListener('click', this.onDocumentClick)
        document.removeEventListener('keyup', this.onEscPress)
    },
    toggle(): void {
        if (this._open) {
            this.close()
        } else {
            this.open()
        }
    },

    // Close cart when clicking anywhere outside the open menu
    onDocumentClick(event: MouseEvent) {
        // TODO: find a better way to pass `this` into the event
        const proxy = event.currentTarget.cartProxy
        if (!proxy.isOpen()) return

        const clickedElement = event.target as HTMLElement
        const cart = document.getElementById('cart')
        if (clickedElement === cart || cart.contains(clickedElement)) return

        proxy.close()
    },

    // Close cart when pressing the escape key
    onEscPress(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            const proxy = event.currentTarget.cartProxy
            if (!proxy.isOpen()) return
            proxy.close()
        }
    }

})

export class JBGShopify {
    private shopifyClient: Shopify
    private cart?
    private jbgCart: typeof ShoppingCart
    private locale: string
    private gtm: GtmSupport | undefined

    constructor(options: JBGShopify.InitOptions) {
        this.shopifyClient = new Shopify({
            shopName: options.shopName,
            apiToken: options.apiToken,
            locale: options.locale,
            countryCode: this.getSaleableCountryCode(),
            enableCart: true
        })
        this.cart = this.shopifyClient.cart
        void this.fetchCart()
        this.jbgCart = ShoppingCart
        this.locale = options.locale
        this.gtm = useGtm()
    }

    async fetchCart() {
        if (process.server) {
            console.warn('Unable to fetch cart on server ..')
            return
        }

        try {
            await this.cart?.refresh()
        } catch (error: unknown) {
            console.warn('Failed to fetch cart:', error)
        }
    }

    // add a give product variant in a given quantity to the cart. creates the cart if it doesn't exist
    public async addItemToCart(
        item: Shopify.Shopify.LineItem,
        qty?: number,
        product?: Shopify.Shopify.Product,
        variant?: Shopify.Shopify.Variant
    ): Promise<void> {
        await this.shopifyClient.cart!.addItems([item])
        await this.refreshCart()

        if (this.gtm) {
            const evt = {
                event: 'add_to_cart',
                shop: {
                    itemId: item.merchandiseId,
                    quantity: item.quantity,
                    numItems: this.jbgCart.getQty(),
                    subtotal: this.jbgCart.getSubtotal(),
                    source: 'jackboxgames.com'
                },
                ecommerce: {}
            }
            if (variant && product) {
                evt.ecommerce = {
                    currency: variant.price.currencyCode,
                    value: Number(variant.price.amount),
                    items: [
                        {
                            // eslint-disable-next-line camelcase
                            item_id: product.id,
                            // eslint-disable-next-line camelcase
                            item_name: product.title,
                            discount: Number(variant.compareAtPrice.amount) - Number(variant.price.amount),
                            // eslint-disable-next-line camelcase
                            item_variant: variant.id,
                            quantity: qty || 1,
                            price: Number(variant.price.amount)
                        }
                    ]
                }
            }
            this.gtm.trackEvent(evt)
        }
    }

    // takes a line item id and quantity, and updates that line item to have the new quantity
    public async updateItemQuantity(item: Shopify.Shopify.Line, quantity: number): Promise<void> {
        const prevItem: JBGShopify.DisplayItem = this.jbgCart.getCart().find((i) => i.id === item.id)
        if (!prevItem) {
            return
        }
        if (quantity === 0) {
            return this.removeItemFromCart(item.id)
        }
        await this.shopifyClient.cart!.updateItems([{
            id: item.id,
            quantity
        }])
        await this.refreshCart()
        const ecommerce = {
            currency: prevItem.currency,
            value: (prevItem.priceValue * quantity),
            items: [
                {
                    // eslint-disable-next-line camelcase
                    item_id: prevItem.productId,
                    // eslint-disable-next-line camelcase
                    item_name: prevItem.title,
                    price: prevItem.priceValue,
                    quantity: quantity - prevItem.quantity
                }
            ]
        }

        if (this.gtm) {
            this.gtm.trackEvent({
                event: quantity > prevItem.quantity ? 'add_to_cart' : 'remove_from_cart',
                ecommerce,
                shop: {
                    itemId: prevItem.id,
                    quantity,
                    numItems: this.jbgCart.getQty(),
                    subtotal: this.jbgCart.getSubtotal(),
                    source: 'jackboxgames.com'
                }
            })
        }
    }

    // takes a line item id and removes it from the cart
    public async removeItemFromCart(lineItemId: string): Promise<void> {
        const item: JBGShopify.DisplayItem = this.jbgCart.getCart().find((item) => item.id === lineItemId)
        await this.shopifyClient.cart!.removeItem([lineItemId])
        await this.refreshCart()

        if (this.gtm) {
            this.gtm.trackEvent({
                event: 'remove_from_cart',
                ecommerce: {
                    currency: item.currency,
                    value: (item.priceValue * item.quantity),
                    items: [
                        {
                            // eslint-disable-next-line camelcase
                            item_id: item.productId,
                            // eslint-disable-next-line camelcase
                            item_name: item.title,
                            price: item.priceValue,
                            quantity: item.quantity
                        }
                    ]
                },
                shop: {
                    itemId: lineItemId,
                    quantity: 0,
                    numItems: this.jbgCart.getQty(),
                    subtotal: this.jbgCart.getSubtotal(),
                    source: 'jackboxgames.com'
                }
            })
        }
    }

    // gets country code from `cloudfront-viewer-country` cookie
    private getSaleableCountryCode() {
        let countryCode = Geolocate.getCountryCode()
        const isSaleableCountry = this.isSaleableCountry(countryCode)
        if (!isSaleableCountry) {
            countryCode = 'US'
        }
        return countryCode
    }

    // Make sure detected country is one of our saleable countries, else fall back to US
    private isSaleableCountry(countryCode: string) {
        // get saleable country list from json
        const saleableCountries = []
        Object.entries(currencyCodeRegionMapJson).forEach((code) => {
            for (let i = 0; i < code.length; i++) {
                saleableCountries.push(code[i])
            }
        })

        if (!countryCode) return false
        if (saleableCountries.indexOf(countryCode) === -1) return false
        return true
    }

    // Automatically set the cart currency based on the `cloudfront-viewer-country` cookie
    public async setCurrency(): Promise<void> {
        // based on an ISO 3166-1 alpha-2 country code
        const saleableCountryCode = this.getSaleableCountryCode()
        await this.shopifyClient.cart!.setCountryCode(saleableCountryCode)
    }

    // get the redirect url to send the user to the checkout page
    public getCheckoutUrl(): string | null {
        if (this.cart) {
            return this.cart.checkoutUrl!
        }

        return null
    }

    // Fetch/update the cart, and return all line items as a displayable object
    public async refreshCart(): Promise<void> {
        await this.setCurrency()
        await this.fetchCart().then(() => {
            if (this.shopifyClient.cart) {
                // Update Cart
                const cart = this.shopifyClient.cart.lines
                const items: JBGShopify.DisplayItem[] = cart.map((line) => {
                    const price: string = line.merchandise?.price?.amount ?? '0'
                    const currency = line.merchandise?.price?.currencyCode ?? 'USD'
                    const image = line.merchandise?.image?.url
                    const isSteamCode = line.merchandise?.product?.collections.some((c) => c.id === 'gid://shopify/Collection/104267284560')
                    const options = line.merchandise?.selectedOptions?.reduce(
                        (msg: string[], val: {value: string}) => {
                            msg.push(val.value)
                            return msg
                        },
                        []
                    )
                    const optionString = options.join(' / ')

                    return {
                        id: line.id!,
                        productId: line.merchandise?.product?.id,
                        variantId: line.merchandise?.id,
                        image,
                        title: line.merchandise?.product?.title ?? 'Product',
                        price: parseFloat(price).toLocaleString(this.locale, { style: 'currency', currency }),
                        currency,
                        priceValue: parseFloat(price),
                        quantity: line.quantity,
                        isSteamCode,
                        optionString
                    }
                })

                // Update subtotal
                const price = this.cart.subtotal!
                const amount: string = price.amount

                const displayPrice = {
                    amount,
                    currencyCode: price.currencyCode
                } as JBGShopify.DisplayPrice

                // Update qty
                let quantity = 0
                items.forEach((lineItem) => {
                    quantity += lineItem.quantity
                })

                // Update Checkout Url
                const checkoutUrl: string = this.cart.checkoutUrl

                // Update cart
                this.jbgCart.updateCart(items, displayPrice, quantity, checkoutUrl)
            }
        })
    }

    // Gets a list of Products in a Collection
    public async fetchCollection(id: number): Promise<Shopify.Shopify.Collection> {
        const collection = await this.shopifyClient.query.getCollectionProducts(id)
        return collection
    }

    // Gets details for a single product
    public async fetchProduct(slug: string): Promise<Shopify.Shopify.Product> {
        const product = await this.shopifyClient.query.getProduct(slug)
        return product
    }

    public formatDecimalPlaces(priceAmount: string, locale: string) {
        const formattedPrice = new Intl.NumberFormat(
            locale,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        ).format(Number(priceAmount))
        return formattedPrice
    }
}

export namespace JBGShopify {
    export interface InitOptions {
        shopName: string
        apiToken: string
        locale: string
    }

    export interface DisplayItem {
        id: string
        productId?: string
        variantId?: string
        image?: string
        title: string
        price: string
        currency: string
        priceValue: number
        quantity: number
        isSteamCode: boolean
        optionString?: string
    }

    export interface DisplayPrice {
        amount: string
        currencyCode: string
    }

    export interface CollectionProduct {
        handle: string
        title: string
        variants: CollectionProductVariant
        featuredImage?: Image
    }

    export interface Image {
        url?: string
        height?: number
        width? : number
    }

    export interface CollectionProductVariant {
        id: string
        price: DisplayPrice
        compareAtPrice: DisplayPrice
        title: string
    }
}
