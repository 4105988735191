<template>
<NuxtLayout>
    <div v-if="error.statusCode === 404" class="padded server-error">
        <div class="server-error-image">
            <img src="/images/blue-lose-404.png" alt="Sad blue guy">
        </div>
        <div class="server-error-text">
            <h1>{{ $t('404.HEADER') }}</h1>
            <h2>{{ $t('404.SUBHEAD') }}</h2>
            <button v-on:click="handleError">{{ $t('GENERAL.BACK_TO_HOMEPAGE') }}</button>
        </div>
    </div>
    <div v-else-if="error.statusCode >= 500" class="padded server-error">
        <div class="server-error-image">
            <img src="/images/blue-lose-404.png" alt="Sad blue guy">
        </div>
        <div class="server-error-text">
            <h1>{{ $t('ACCOUNT.VERIFY_ERROR.HEADER') }}</h1>
            <!--TODO: Show error message + stack-trace in dev mode.-->
            <button v-on:click="handleError">{{ $t('GENERAL.BACK_TO_HOMEPAGE') }}</button>
        </div>
    </div>
    <div v-else class="padded server-error">
        <div class="server-error-image">
            <img src="/images/blue-lose-404.png" alt="Sad blue guy">
        </div>
        <div class="server-error-text">
            <h1>{{ $t('ACCOUNT.VERIFY_ERROR.HEADER') }}</h1>
            <h2>Error: {{ error.statusCode }}</h2>
            <button v-on:click="handleError">{{ $t('GENERAL.BACK_TO_HOMEPAGE') }}</button>
        </div>
    </div>
</NuxtLayout>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const localePath = useLocalePath()

const props = defineProps({
    error: Object as () => NuxtError
})

const handleError = () => {
    console.debug('Handled Error:', props.error)
    void clearError({
        redirect: localePath('/')
    })
}
</script>

<style lang="scss">
@use "$styles/kit.scss" as *;

.server-error {
    display: flex;
    gap: 30px;
    align-items: center;
    margin: auto;
    height: 100vh;

    @include mq-xsmall {
        flex-direction: column;
        text-align: center;
        padding: 20px;
    }
    .server-error-image {
        text-align: right;
        flex: 1;
        justify-self: right;

        @include mq-xsmall {
            flex: 0;
        }
    }
    .server-error-text {
        flex: 1;
        justify-self: left;

        @include mq-xsmall {
            flex: 0;
        }

        button {
            margin-top: 12px;
        }
    }
    h1 {
        margin: 0;
        font-size: 32px;
        max-width: 300px;
        @include mq-xsmall {
            font-size: 28px;
        }
    }
    h2 {
        font-size: 16px;
        font-weight: normal;
        max-width: 300px;
        @include mq-xsmall {
            font-size: 14px;
        }
    }
}
</style>
